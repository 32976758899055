import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import routes from "./routes/routes";
import MainLayout from "./components/layout/MainLayout";
import PageWrapper from "./components/common/PageWrapper";
import Aos from "aos";

import "aos/dist/aos.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import Page404 from "./components/common/404/Page404";

const App = () => {
  useEffect(() => {
    Aos.init({ once: true });
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          {routes.map((route, index) =>
            route.index ? (
              <Route
                index
                key={index}
                element={
                  route.state ? (
                    <PageWrapper state={route.state}>
                      {route.element}
                    </PageWrapper>
                  ) : (
                    route.element
                  )
                }
              />
            ) : (
              <Route
                path={route.path}
                key={index}
                element={
                  route.state ? (
                    <PageWrapper state={route.state}>
                      {route.element}
                    </PageWrapper>
                  ) : (
                    route.element
                  )
                }
              />
            )
          )}
        </Route>
        <Route path="*" element={<Page404 />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
